import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import ('@/views/HomePage.vue')
  },
  {
    path: '/best-picture',
    component: () => import ('@/views/UserBallotsPage.vue')
  },
  {
    path: '/animated-feature-film',
    component: () => import ('@/views/UserBallotsPage.vue')
  },
  {
    path: '/best-picture/ballots/:id',
    component: () => import ('@/views/PicksPage.vue')
  },
  {
    path: '/animated-feature-film/ballots/:id',
    component: () => import ('@/views/PicksPage.vue')
  },
  {
    path: '/best-picture/results',
    component: () => import ('@/views/ResultsPage.vue')
  },
  {
    path: '/animated-feature-film/results',
    component: () => import ('@/views/ResultsPage.vue')
  },
  {
    path: '/best-picture/remove-users',
    component: () => import ('@/views/RemoveUsersPage.vue')
  },
  {
    path: '/animated-feature-film/remove-users',
    component: () => import ('@/views/RemoveUsersPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
